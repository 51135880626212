import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './responsive.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Biography from "./Biography";
import Oops from "./Oops";
import "@fontsource/righteous";
import "@fontsource/righteous/400.css";
import Downloads from "./Downloads";
import Events from "./Events";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path={"/"} element={<App />} />
              <Route path={"biography"} element={<Biography />} />
              <Route path={"downloads"} element={<Downloads />} />
              <Route path={"events"} element={<Events />} />
              {/*<Route path={"contact"} element={<Contact />} />*/}

              <Route path="/nl/" element={<Navigate to="/" replace/>}/>
              <Route path="/pagina/showcase" element={<Navigate to="/" replace/>}/>
              <Route path="/pagina/biografie" element={<Navigate to="/" replace/>}/>

              <Route path={"*"} element={<Oops/>}/>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
