import React, {useEffect} from 'react';
import './App.css';
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import Bio from "./components/Bio";
import HeaderSmall from "./components/HeaderSmall";

function Biography() {
    useEffect(() => {
        document.title = "Biography | Jeroen Egelmeers | Prompt Engineering Advocate | Public Speaker"
    }, []);

  return (
    <div className="App  flex flex-col min-h-screen">
        <HeaderSmall headerText="About Jeroen Egelmeers" />

        <div className="clearfix"></div>

        <div className={"flex-grow"}>
            <Bio />
        </div>

        <Footer />

        <Copyright />
    </div>
  );
}

export default Biography;
